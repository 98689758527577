




























































import Vue from "vue";

// import axios from 'axios'
// import states from '@/assets/dictionaries/state-list.ts';
declare module "@/assets/dictionaries/state-list";

export default Vue.extend ({
    data() {
        return {
            expandOnHover : true,
            mobile : "reduce",
            reduce : true,
            menuItems : [
                {
                    routeName: "AdminDashboard",
                    title: "Dashboard",
                    icon: "tachometer-alt"
                },
                {
                    routeName: "AdminSession",
                    title: "Sessions",
                    icon: "clipboard-list"
                },
                {
                    routeName: "AdminAnalytics",
                    title: "Analytics",
                    icon: "chart-bar"
                },
                {
                    routeName: "AdminOptions",
                    title: "Options",
                    icon: "cog"
                },
                {
                    routeName: "Home",
                    title: "Back to Site",
                    icon: "home"
                }
            ]
        }
    },
    methods: {
        routeTo(routeString: string) {
            this.$router.push({
                name: routeString
            });
        }
    }

})
